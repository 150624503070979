@media screen and (max-width: 1199px) {
    .container {
        width: 992px;
    }

    .cap__left{
        padding-right: 0;
        border: none;
    }

    .cap__right_item--2{
        margin-left: 15px;
    }

    .cap__button{
        margin-left: 15px;
    }

    .cap__right_item{
        font-size: 14px;
    }

    .video__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        justify-content: center;
        -ms-align-items: center;
        align-items: center;
    }

    .video__wrapper {
        max-width: 785px;
    }

    .video__title {
        text-align: center;
        margin-bottom: 40px;

        br {
            display: none;
        }
    }

    .cases__item {
        margin: 0 auto;
    }

    .cases .slider__arrow {
        left: 0;
    }

    .cases .slider__next_2 {
        right: 0;
        left: auto;
    }

    .cases__slider {
        left: 0;
    }

    .about__img {
        width: 514px;
    }

    .team__item {
        max-width: 430px;
    }

    .team__name {
        font-size: 20px;
    }

    .reviews__right {
        display: none;
    }

    .reviews__item {
        margin: 0 auto;
    }

    .reviews__slide {
        padding-bottom: 0;
    }

    .social__box {
        margin-right: 0;
    }

    h2 {
        font-size: 28px;
    }

    .debts__item {
        width: 450px;
        max-width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .container {
        width: 768px;
    }

    .header {
        background: #2D5A73;
    }

    .cap{
        flex-wrap: wrap;
    }

    .first__item{
        margin-right: 20px;
    }

    .first {
        padding: 20px 0 70px;
    }

    .quiz__box {
        padding: 20px 0;
    }

    .manual__nums {
        display: none;
    }

    .manual__box {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        -o-flex-wrap: wrap;
        flex-wrap: wrap;
    }

    .manual__item> ::before {
        display: none;
    }

    .manual__item {
        margin-top: 30px;
    }

    .manual__bottom_text {
        font-size: 20px;
    }

    .video__wrapper {
        max-width: 100%;
    }

    .video__item {
        position: relative;
        max-width: 360px;
        min-width: 360px;
    }

    .video__title {
        margin-top: 0;
    }

    .debts__item {
        width: 344px;
    }

    .about__img {
        display: none;
    }

    .about__button {
        margin-bottom: 55px;
    }

    .team__item {
        max-width: 320px;
    }

    .social__box {
        margin-right: auto;
    }

    .social {
        background: url(../img/manual-bg.png) no-repeat;
        -webkit-background-size: cover;
        background-size: cover;
    }

    .order__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        text-align: center;
        -ms-align-items: center;
        align-items: center;
    }

    .order__box {
        padding: 0;
    }

    .order__title {
        text-align: center;
    }

    .order__bottom {
        margin: 30px 0;
    }

    .manual__box :last-child {
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 767px) {
    .container {
        width: 375px;
    }

    .cap {
        justify-content: center;
    }

    .button {
        width: 100%;
        max-width: 100%;
        height: 52px;
    }

    .cap__button {
        margin-top: 20px;
    }

    .cap__contacts {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;

        br {
            display: block;
        }
    }

    .phone {
        margin-left: 0;
        margin-top: 10px;
    }

    h1 {
        font-size: 25px;
        line-height: 39px;
    }

    .first__label {
        font-size: 18px;
    }

    .first__label::after {
        margin-bottom: 16px;
    }

    .first {
        padding: 0 0 40px 0;
    }

    h2 {
        font-size: 22px;
        line-height: 32px;
    }

    .quiz__title {
        font-size: 18px;
        line-height: 24px;

        br {
            display: none;
        }
    }

    .quiz__nums li::before {
        width: 40px;
        left: -40px;
    }

    .quiz__nums li {
        margin-right: 40px;
    }

    .quiz__prev,
    .quiz_next {
        width: 48%;
    }

    .quiz__input {
        width: 100%;
    }

    .quiz__submit {
        width: 48%;
    }

    .quiz {
        min-height: 670px;
        padding-bottom: 0;
    }

    .manual__bottom_text {
        font-size: 16px;
        line-height: 24px;
    }

    .manual {
        padding: 30px 0 40px 0;
    }

    .video__item {
        max-width: 344px;
        min-width: auto;
    }

    .video {
        padding-top: 30px;
    }

    .debts {
        padding: 30px 0 40px 0;
    }

    .cases {
        padding-top: 30px;
    }

    .about__top {
        display: none;
    }

    .about__text {
        font-size: 18px;
        line-height: 28px;
    }

    .about {
        padding: 30px 0;
    }

    .about__button {
        font-size: 15px;
    }

    .parners__box {
        margin-top: 40px;
    }

    .partners {
        padding: 30px 0;
    }

    .team__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
    }

    .team__title {
        font-size: 18px;
        line-height: 30px;

        br {
            display: none;
        }
    }

    .team__item {
        margin-bottom: 20px;
    }

    .team__text {
        padding: 10px;
    }

    .team__name {
        font-size: 18px;
    }

    .team__position {
        font-size: 16px;
    }

    .team {
        padding-bottom: 40px;
    }

    .reviews__body,
    .reviews__head {
        padding: 10px;
    }

    .reviews__controls {
        margin: 30px auto 0 auto;
        justify-content: center;
    }

    .reviews {
        padding-bottom: 40px;
    }

    .social__item {
        max-width: 100%;
        padding-left: 70px;
        font-size: 18px;
        height: 60px;
    }

    .social__bottom {
        margin-top: 30px;
    }

    .social__wrapper {
        margin-top: 0;
    }

    .social {
        padding: 30px 0 40px 0;
    }

    .questions {
        padding: 30px 0 40px 0;
    }

    .order__title {
        font-size: 20px;

        br {
            display: none;
        }
    }

    .order__right {
        width: 100%;
    }

    .order {
        padding: 30px 0 40px 0;
    }

    .contacts__box {
        width: 100%;
    }

    .map {
        position: static;
        height: 300px;
        margin-top: 30px;
    }

    .contacts__box {
        padding: 0;
    }

    .contacts__title {
        margin-bottom: 30px;
    }

    .contacts {
        padding: 30px 0 0 0;
    }

    .modal .order__right {
        width: 320px;
        padding: 30px 10px;
    }

    .input {
        width: 100%;
    }

   .cap .adres {
        text-align: center;
    }

    .cap__right{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .cap__right_item{
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        background-position: center top;
        padding: 25px 0 0;
    }

    .cap__right_item--2{
        margin-left: 0;
    }

    .cap__button{
        margin: 0 0 20px;
    }

    h1{
        font-size: 25px;
    }

    .first__label{
        font-size: 16px;
        line-height: 20px;
    }

    .first__sub-title{
        font-size: 25px;
    }

    .first__box{
        display: block;
        margin-top: 20px;
    }

    .first__item{
        margin: 0;
        text-align: center;
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 374px) {
    .first__title {
        br {
            display: none;
        }
    }

    .manual__box {
        -webkit-flex-direction: column;
        -moz-flex-direction: column;
        -ms-flex-direction: column;
        -o-flex-direction: column;
        flex-direction: column;
        -ms-align-items: center;
        align-items: center;
    }

    .video__item {
        max-width: 288px;
        margin: 0 auto;
    }

    .debts__item{
    	font-size: 18px;
    }

    .about__button{
    	font-size: 14px;
    }

    .parners__title span{
    	font-size: 17px;
    }

    .video__slider{
    	margin-right: 0;
    }
}